import { useFormikContext, type FormikHelpers } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { UploadConfigMap, type UploadConfig } from 'src/api'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { type FileState } from 'src/hooks/fileUpload'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester } from 'src/hooks/semesters'
import { Button } from 'src/tailwind/components/Button'
import BackButton from 'src/views/components/BackButton'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import FileInput from 'src/views/components/forms/formik/FileInput'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import TextInput from 'src/views/components/forms/formik/TextInput'
import Loader from 'src/views/components/Loader'

interface FormikValues {
  readonly title: string
  readonly url: string
  readonly file: readonly FileState[]
}
export default function LecturerCoursesMaterialsAddPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:materials')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const locale = useLocale()
  const { courseId } = useParams()
  const t = useTranslatable()
  const [searchParams] = useSearchParams()
  const params = useParams()
  const initialValues: FormikValues = { title: '', url: '', file: [] }
  const headers = useAuthenticatedHeaders()
  const semIdParam = searchParams.get('semId')
  const activeSemester = useActiveSemester()
  const navigate = useNavigate()
  const semId = semIdParam ?? activeSemester?.id

  async function onSubmit(values: FormikValues, formikHelpers: FormikHelpers<FormikValues>): Promise<void> {
    const { title, url, file } = values
    const fileId = file[0]?.remoteFile?.id
    const hasUrl = url.length > 0
    const hasFile = fileId != null && file.length > 0
    await Api.postLecturerCoursesMaterials({
      headers,
      body: {
        semId: semId!,
        title,
        url: !hasFile && hasUrl ? url : null,
        fileUid: hasFile != null && !hasUrl ? fileId! : null,
      },
      args: {
        id: params.courseId!,
      },
    })

    toast.success(t('course:file_added'))
    formikHelpers.resetForm()
    setSuccessfullySubmited(true)
  }

  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/lecturer/courses/${courseId!}/materials/?semId=${semId!}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])
  const config = UploadConfigMap.lecturerCoursesMaterialsUpload(courseId!)
  return (
    <>
      <h3 className="font-bold">{t('course:add_materials')}</h3>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        classNames={{ form: 'w-full' }}
        isConfirmable={!successfullySubmited}
      >
        <FormContent config={config} />
      </Form>
    </>
  )
}

function FormContent({ config }: { config: UploadConfig }): React.ReactElement | null {
  const id = `label-${React.useId()}`
  const [fileInputId] = React.useState(() => `${id}-${Date.now()}`)
  const formik = useFormikContext<FormikValues>()
  const t = useTranslatable()
  const locale = useLocale()
  const { courseId } = useParams()
  const [searchParams] = useSearchParams()
  const semIdParam = searchParams.get('semId')
  const activeSemester = useActiveSemester()
  const semId = semIdParam ?? activeSemester?.id

  // const postPending = useStore(Api.postLecturerCoursesMaterials.pending)
  // TODO
  const postPending = false

  return (
    <div className="mt-6 w-full rounded-card bg-card px-6 py-9">
      <FormError />
      <TextInput
        type="text"
        name="title"
        placeholder={t('message:enter_the_title')}
        required
        label={t('common:title')}
      />
      <TextInput
        type="text"
        name="url"
        label={t('file:file_url')}
        placeholder={t('common:enter_the_url')}
        disabled={formik.values.file.length > 0}
      />
      <FileInput
        key={fileInputId}
        config={config}
        onChange={(file) => void formik.setFieldValue('file', file)}
        disabled={formik.values.url.length > 0}
      />

      <div className="mt-4 flex flex-wrap justify-end">
        <BackButton link={`/${locale}/lecturer/courses/${courseId!}/discussions?semId=${semId!}`} />
        <Button
          type="submit"
          className="ml-2"
          variant="red"
          disabled={
            !formik.isValid ||
            postPending ||
            formik.values.title == null ||
            (formik.values.url === '' && formik.values.file.length === 0)
          }
        >
          {t('common:save')}
        </Button>
      </div>
    </div>
  )
}
